/* ************************** */
/* HEADER*/
/* ************************** */

.header {
  height: 100vh;
  position: relative;
  min-height: 67rem;

  background-image: linear-gradient(
      rgba(34, 34, 34, 0.6),
      rgba(34, 34, 34, 0.6)
    ),
    url(../img/Cover_photo_website_original.webp);
  background-size: cover;
  color: #fff;
  margin-bottom: 9.6rem;
  /* clip-path: polygon(0 0, 100% 0, 100% 90vh, 0 100%); */
}

.logo {
  height: 6.2rem;
}

/* ************************** */
/* NAVIGATION */
/* ************************** */

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 130rem;
  margin: 0 auto;
  padding: 2.2rem 0;
}

.main-nav-list {
  display: flex;
  gap: 2.4rem;
  align-items: center;
  list-style: none;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  font-size: 2rem;

  transition: all 0.3s;
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #e2d9cf;
}

.header-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.heading-text {
  font-size: 2rem;
  line-height: 1.6;
  font-weight: 500;
  margin-bottom: 4.8rem;
  text-align: center;
}

/* ************************** */
/* SECTION EXPLORE */
/* ************************** */
.section-explore {
  padding: 9.6rem 0;
}

.explore-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.explore-box-text {
  font-size: 2.4rem;
  font-weight: 500;
  color: #333;
}

/* .explore-1 {
  background-image: url(../img/explore/explore-2.JPG);
  background-size: cover;
  background-position: center;
} */

.explore-link:link,
.explore-link:visited {
  text-decoration: none;
  display: flex;
}

.explore-img-box {
  overflow: hidden;
  border-radius: 7px;
  height: 100%;
  width: 100%;
}

.explore-img {
  width: 100%;
  height: 100%;
  overflow: none;
  transition: all 0.4s;
}

.explore-img:hover {
  transform: scale(105%);
}

.explore-text {
  font-size: 3rem;
  font-weight: 700;
  margin-top: 0.6rem;
  color: #555;
}

/* ************************** */
/* SECTION EVENTS */
/* ************************** */
.section-events {
  padding: 9.6rem 0;
  background-color: #eee;
}

.section-events span,
.section-events h2 {
  text-align: center;
}

.event-box {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2rem 2rem 2rem rgba(0, 0, 0, 0.075);
  border-radius: 7px;
  overflow: hidden;
}

.event-header {
  font-size: 3rem;
  align-self: center;
}

.event-img {
  width: 100%;
  height: 100%;
}

.event-content {
  font-size: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
}

.event-link {
  align-self: center;
  display: flex !important;
  align-items: center;
  gap: 2rem;
}

.event-content-description {
  font-size: 1.8rem;
  line-height: 1.6;
}

.event-icon {
  height: 2rem;
  width: 2rem;
  font-size: 2rem;
  color: #fff;
}

/* ************************** */
/* SECTION LOCATIONS */
/* ************************** */
.section-locations {
  padding: 9.6rem 0;
}

.section.videos h2 {
  margin-bottom: 4.8rem;
}

.video-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  column-gap: 2.4rem;
  row-gap: 4.8rem;
  padding-bottom: 9.6rem;
  border-bottom: 1px solid #eee;
}

.video-wrapper:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.video-wrapper-header {
  grid-column: 1 / -1;
  font-size: 7.4rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.2rem;
  justify-self: center;
  margin-top: 4.8rem;
}
.video-wrapper-header span {
  color: #766b5f;
  font-weight: 700;
  text-transform: uppercase;
}

.video-carousel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4rem;
  max-height: 60rem;
  /* background-color: orange; */
  /* overflow: hidden; */
}

.video-wrapper iframe {
  box-shadow: 0 1.6rem 3.2rem 0 rgba(51, 51, 51, 0.1);
  width: 100%;
  height: 20rem;
  /* border-radius: 9px; */
}

/* TRAILFORKS MAP*/
.trailforks-widget {
  max-height: 20rem;
}

.trailforks-widget-text:link,
.trailforks-widget-text:visited {
  text-decoration: none;
  color: #999;
}
.trailforks-widget-text:hover,
.trailforks-widget-text:active {
  text-decoration: none;
  color: #555;
}

.area-info-box {
  grid-row: 2 / span 2;
  grid-column: 3 / 4;
  /* background-color: red; */
  padding: 2.4rem;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.area-info-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.area-info-list-item {
  font-size: 2rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.area-info-list-item:last-child {
  margin-bottom: 4rem;
}

.area-info-list-item span {
  font-weight: 700;
}

/* ICONS */
.area-info-icon {
  height: 2.4rem;
  width: 2.4rem;
  font-size: 2.4rem;
  color: #766b5f;
}

.area-links {
  display: flex;
  gap: 1.6rem;
}

.area-link-logo:link,
.area-link-logo:visited {
  text-decoration: none;

  transition: 0.3s;
}
/* .area-info-list-logo:hover,
.area-info-list-logo:active {
  text-decoration: none;
  color: red;
} */

.youtube:hover {
  color: #ff0000;
}

.twitter:hover {
  color: #1da1f2;
}
.facebook:hover {
  color: #4267b2;
}
.chrome:hover {
  color: #c5b29f;
}

/* ************************** */
/* SECTION GALLERY */
/* ************************** */
.section-gallery {
  background-color: #eee;
}
